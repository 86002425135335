import type { PromoConfig } from './types'

export class PromoPhase {
    period: { start: Date; end: Date }

    countdown: boolean

    pricebooks: {
        promoBook: number // Promo prices

        fullPriceBook: number // The original prices
    }

    banner: PromoConfig['banner']

    checkout: PromoConfig['checkout']

    ctm?: PromoConfig['ctm']

    colourVariant: 'purple' | 'green'

    constructor(phase: PromoPhase) {
        this.period = phase.period

        this.countdown = phase.countdown
        this.pricebooks = phase.pricebooks
        this.banner = phase.banner
        this.ctm = phase.ctm
        this.checkout = phase.checkout
        this.colourVariant = phase.colourVariant
    }
}
