import { PromoPhase } from './promo-phase.class'

interface PromoPhaseInput {
    period: {
        start: Date
        end: Date
    }
    countdown?: boolean
    pricebooks?: {
        promoBook?: number
        fullPriceBook?: number
    }
    banner?: Partial<PromoPhase['banner']>
    checkout?: Partial<PromoPhase['checkout']>
    ctm?: Partial<PromoPhase['ctm']>
    colourVariant: 'purple' | 'green'
}

export class Promo {
    phases: PromoPhase[] = []

    colourVariant: 'purple' | 'green' = 'purple'

    end: Date | null = null

    addPhase(phase: PromoPhaseInput): PromoPhase {
        if (!(phase.period?.start && phase.period.end)) {
            throw Error('No startDate or endDate provided')
        }
        if (phase.period.end <= phase.period.start) {
            throw Error('End date must be after start date')
        }

        if (this.phases.length) {
            const prevPhase = this.phases[this.phases.length - 1]

            if (prevPhase.period.end >= phase.period.start) {
                throw Error('Start date must be after the previous phase end date')
            }

            this.phases.push(
                new PromoPhase({
                    ...prevPhase,
                    ...phase,
                    // Only add ctm if it exists in either the previous or current phase
                    ...((prevPhase?.ctm || phase?.ctm) && {
                        ctm: { ...prevPhase.ctm, ...phase.ctm } as any,
                    }),
                    period: {
                        ...prevPhase.period,
                        ...phase.period,
                    },
                    pricebooks: {
                        ...prevPhase.pricebooks,
                        ...phase.pricebooks,
                    },
                    banner: {
                        ...prevPhase.banner,
                        ...phase.banner,
                    },
                    checkout: {
                        ...prevPhase.checkout,
                        ...phase.checkout,
                    },
                    colourVariant: phase.colourVariant,
                })
            )
        } else {
            this.phases.push(new PromoPhase(phase as PromoPhase))
        }

        this.end = phase.period.end

        return this.phases[this.phases.length - 1]
    }

    isActive(): boolean {
        const now = new Date()
        return this.phases.some((phase) => {
            return phase.period.start <= now && phase.period.end >= now
        })
    }

    getCurrentPhase(): PromoPhase | null {
        const now = new Date()
        return (
            this.phases.find((phase) => {
                return phase.period.start <= now && phase.period.end >= now
            }) ?? null
        )
    }
}
