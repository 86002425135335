import { Promo } from '../promo.class'

export const corePromo = new Promo()

corePromo.addPhase({
    period: {
        start: new Date(2025, 2, 12, 10, 0, 0),
        end: new Date(2025, 2, 19, 23, 59, 59),
    },
    countdown: true,
    pricebooks: {
        promoBook: 66,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: <>Limited Time Offer - Up to 23% Off&nbsp;</>,
        primaryTextMobile: <>Limited Time Offer - Up to 23% Off&nbsp;</>,
        secondaryText: (
            <>
                <span>
                    <a
                        href="htthttps://www.hometree.co.uk/wp-content/uploads/2025/03/March-2025-Up-to-23-Off-Promotion-Offer-Terms.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    apply
                </span>
            </>
        ),
    },
    checkout: {
        strikethrough: true,
    },
    colourVariant: 'green',
})

corePromo.addPhase({
    period: {
        start: new Date(2025, 2, 20, 10, 0, 0),
        end: new Date(2025, 3, 3, 23, 59, 59),
    },

    countdown: true,
    pricebooks: {
        promoBook: 67,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: <>Limited Time Offer - Up to 20% Off&nbsp;</>,
        primaryTextMobile: <>Limited Time Offer - Up to 20% Off&nbsp;</>,
        secondaryText: (
            <>
                <span>
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2025/03/March_April-2025-Up-to-20-Off-Promotion-Offer-Terms.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    apply
                </span>
            </>
        ),
    },
    checkout: {
        strikethrough: true,
    },
    colourVariant: 'green',
})
