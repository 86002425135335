import { Promo } from '../promo.class'

export const ctmPromo = new Promo()
ctmPromo.addPhase({
    period: {
        start: new Date(2025, 2, 5, 9, 0, 0),
        end: new Date(2025, 3, 30, 23, 59, 59),
    },
    countdown: true,
    pricebooks: {
        promoBook: 68,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: 'Limited SALE - up to 25% off selected plans',
        primaryTextMobile: <>Save up to 25% on select plans</>,
        secondaryText: (
            <>
                For new customers only; see{' '}
                <a
                    href="https://www.hometree.co.uk/wp-content/uploads/2025/03/CtM-Hometree-March-Staggered-Discount-Promotion-TCs.docx.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    T&Cs
                </a>{' '}
                for details
            </>
        ),
    },
    ctm: {
        exclusive: true,
        stickers: {
            enabled: true,
            text: null,
            yourBoiler: (
                <>
                    8%
                    <br />
                    off
                </>
            ),
            yourHeating: (
                <>
                    15%
                    <br />
                    off
                </>
            ),
            yourHome: (
                <>
                    25%
                    <br />
                    off
                </>
            ),
        },
        landing: {
            header: <></>,
            subheader: <></>,
        },
        plans: {
            header: 'Up to 25% Off Select Plans Until 30th April',
            headerMobile: '25% Off Select Plans Until 30th April 2025',
            subheader: (
                <>
                    Offer ends at midnight 30th April - for new customers only; see{' '}
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2025/01/CtM-Hometree-JanuaryStaggered-Discount-Promotion-TCs.docx-1.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    for details
                </>
            ),
            strikethrough: true,
        },
    },
    checkout: {
        strikethrough: true,
    },
    colourVariant: 'purple',
})
